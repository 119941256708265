"use client"

import {useTheme} from "next-themes"
import {Toaster as Sonner} from "sonner"
import {buttonVariants} from "./button"
import {cn} from "@/lib/utils"
import {CheckIcon, CircleCheck, CircleCheckIcon, CircleX, Loader, LoaderCircle} from "lucide-react"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({...props}: ToasterProps) => {
  return (
    <Sonner
      icons={{
        success: <CircleCheck className="stroke-success-500" />,
        loading: <LoaderCircle className="animate-spin" />,
        error: <CircleX className="stroke-destructive" />,
      }}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            "bg-background flex gap-2 items-center w-full p-4 rounded-md text-foreground border border-border shadow-lg",
          content: "flex flex-col gap-1",
          description: "text-muted-foreground text-sm",
          title: "text-sm font-medium",
          actionButton: cn(buttonVariants({size: "sm"})),
          cancelButton: "bg-muted text-muted-foreground",
          icon: "size-6",
        },
      }}
      {...props}
    />
  )
}

export {Toaster}
